@use 'libs/styles/responsive';

.brand-highlight {
}

.brand-highlight__inner {
  padding-top: 48px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 64px;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--content-max-width);

  @include responsive.target(md_landscape, xl) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.brand-highlight__visuals {
  margin-bottom: 48px;

  @include responsive.target(md_landscape, xl) {
    display: flex;
    grid-gap: 16px;
  }
}

.brand-highlight__left,
.brand-highlight__right {
  @include responsive.target(md_landscape, xl) {
    display: flex;
    flex-direction: column;
  }
}

.brand-highlight__left {
  @include responsive.target(md_landscape, xl) {
    align-items: flex-end;
    width: 57.8%;
  }
}

.brand-highlight__right {
  @include responsive.target(md_landscape, xl) {
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
  }
}

.brand-highlight__top-left,
.brand-highlight__top-right,
.brand-highlight__bottom-left,
.brand-highlight__bottom-right {
  opacity: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 24px;
  position: relative;

  @include responsive.target(md_landscape, xl) {
    margin-bottom: 16px;

    @supports not (aspect-ratio: 1) {
      overflow: hidden;
    }
  }
}

.brand-highlight__top-left {
  @include responsive.target(md_landscape, xl) {
    aspect-ratio: 768/624;

    @supports not (aspect-ratio: 1) {
      padding-top: 81%;
      margin-right: 8px;
    }
  }
}
.brand-highlight__top-right {
  @include responsive.target(md_landscape, xl) {
    aspect-ratio: 544/384;

    @supports not (aspect-ratio: 1) {
      padding-top: 71%;
      margin-left: 8px;
    }
  }
}
.brand-highlight__bottom-left {
  @include responsive.target(md_landscape, xl) {
    width: 70.8%;
    aspect-ratio: 544/388;

    @supports not (aspect-ratio: 1) {
      padding-top: calc(71% * 0.708); // times width
      margin-right: 8px;
    }
  }
}
.brand-highlight__bottom-right {
  @include responsive.target(md_landscape, xl) {
    width: 58.8%;
    aspect-ratio: 320/426;

    @supports not (aspect-ratio: 1) {
      margin-left: 8px;
      padding-top: calc(133% * 0.588); // times width
    }
  }
}

.brand-highlight__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 178px;
  max-height: 60px;
  z-index: 1;

  @include responsive.target(md_landscape, xl) {
    max-height: 90px;
    max-width: 280px;
  }
}

.brand-highlight__image {
  display: block;
  width: 100%;
  height: 100%;

  @include responsive.target(md_landscape, xl) {
    position: absolute;
    object-fit: cover;
    object-position: var(--brand-highlight-image-focus-x, center)
      var(--brand-highlight-image-focus-y, center);

    @supports not (aspect-ratio: 1) {
      top: 0;
      left: 0;
    }
  }
}

.brand-highlight__text {
  color: var(--color-grey--mid);
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}
