.simple-news-list {
}

.simple-news-list__inner {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--content-vertical-padding);
  padding-left: var(--content-side-padding-large);
  padding-right: var(--content-side-padding-large);
  padding-bottom: var(--content-vertical-padding);
}

.simple-news-list__top {
  margin-bottom: 44px;
}

.simple-news-list__main {
  margin-bottom: 36px;
}

.simple-news-list__bottom {
}

.simple-news-list__button {
}
