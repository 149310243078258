@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function rem($font-size, $base: $font-size-xl) {
  @return #{strip-unit($font-size) / strip-unit($base)}rem;
}

@mixin nice-fonts {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin clearfix {
  &:after {
    content: '.';
    clear: both;
    display: block;
    visibility: hidden;
    height: 0;
  }
}

@mixin smooth-scrolling {
  -webkit-overflow-scrolling: touch;
}

@mixin no-scrollbar {
  &::-webkit-scrollbar {
    appearance: none;
    display: none;
  }

  scrollbar-width: 0;
}

@mixin ckeditor_style {
  .text-plugin-content,
  .cke_editable {
    @content;
  }
}
