@use 'libs/styles/helpers';

*,
*::before,
*::after {
  font-family: inherit;
  letter-spacing: inherit;
  @include helpers.nice-fonts;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  font-family: var(--font-gotham-ssm, sans-serif);
  height: 100%;
  width: 100%;
  letter-spacing: -0.015em;
}

.hidden {
  display: none;
}

img {
  opacity: 0;

  &.loaded {
    opacity: 1;
  }
}
