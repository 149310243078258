@use 'libs/styles/responsive';

.job-openings {
}

.job-openings__inner {
  padding-top: 114px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 40px;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(lg, xl) {
    padding-top: 172px;
    padding-bottom: 88px;
  }
}

.job-openings__text {
  margin-bottom: 40px;
  max-width: 588px;

  @include responsive.target(lg, xl) {
    margin-bottom: 60px;
    padding-left: 36px;
  }
}

.job-openings__iframe {
  margin-bottom: 40px;
  max-width: 800px;

  @include responsive.target(lg, xl) {
    max-width: 655px;
    margin-bottom: 60px;
    padding-left: 36px;
  }
}

.job-openings__list {
  @include responsive.target(md, xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  @include responsive.target(lg, xl) {
    grid-template-columns: repeat(3, 320px);
    grid-gap: 16px;

    // spacer to leave out 3rd position in first row
    &::before {
      content: '';
      display: block;
      grid-row: 1;
      grid-column: 3;
    }

    .job-openings--6-items & {
      &::before {
        display: none;
      }
    }
  }
}

.job-openings__item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 0;
  }
}

.job-opening {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  background-color: var(--color-white);
  padding-top: 36px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 16px;
  height: 200px;

  @include responsive.target(lg, xl) {
    width: 320px;
  }
}

.job-opening__visual {
}

.job-opening__image {
  max-width: 244px;
  max-height: 46px;
  height: 100%;

  @include responsive.target(xs) {
    max-width: 100%;
  }
}

.job-opening__button {
  .job-opening:hover & {
    --cal-button-primary-color: var(--color-grey--mid);
  }
}

.job-openings__mobile-visual {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 193px;
  height: 734px;
  pointer-events: none;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;

  @include responsive.target(md, xl) {
    width: 25%;
  }

  @include responsive.target(lg, xl) {
    display: none;
  }
}

.job-openings__visual {
  display: none;

  @include responsive.target(lg, xl) {
    display: block;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -100px;
    height: calc(100px + 100%);
    width: 100%;
  }
}

.job-openings__image {
  @include responsive.target(lg, xl) {
    position: absolute;
    left: calc(50% + #{var(--content-max-width)} * 0.5 - 538px);
    right: 0;
    height: 100%;
    width: auto;
    --cal-parallax-image-background-size: auto 120%;
  }
}
