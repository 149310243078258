@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.anchor-container {
}

.anchor-container--visible {
}

.anchor-container--root {
}

.anchor-container--no-children.anchor-container--visible {
  padding: 40px;
  position: relative;
  z-index: var(--content-block-z-index);
  background-color: var(
    --content-block-override-background-color,
    var(--color-white)
  );

  @include responsive.target(xs, md) {
    padding: 24px;
  }
}
