@use 'libs/styles/responsive';

.dividend-table {
  position: relative;

  @include responsive.target(lg, xl) {
    min-height: 800px;
    background-color: #eeeeee;
    overflow: hidden;
  }
}

.dividend-table__background-image {
  background-color: var(--color-grey--warm-light);
  width: 100%;
  padding-top: 86.67%;
  background-size: cover;

  @include responsive.target(md, xl) {
    padding-top: 56.25%;
  }

  @include responsive.target(lg, xl) {
    position: absolute;
    top: 50%;
    left: 50%;
    padding-top: 0;
    width: 2440px;
    height: 950px;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }
}

.dividend-table__inner {
  padding-top: 80px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 80px;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(lg, xl) {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 168px;
    padding-left: 0;
    padding-bottom: 0;
    z-index: 10;
  }
}

.dividend-table__content {
  @include responsive.target(lg, xl) {
    width: 687px;
  }
}

.dividend-table__title {
  margin-bottom: 20px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.dividend-table__table {
  @include responsive.target(md, xl) {
    display: table;
    border-collapse: collapse;
    border-spacing: 16px;
    width: 100%;
  }
}

.dividend-table__title-row {
  display: none;

  @include responsive.target(md, xl) {
    display: table-row;
    border-bottom: 1px solid var(--color-red);
  }
}

.dividend-table__heading {
  @include responsive.target(md, xl) {
    display: table-cell;
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    padding-bottom: 8px;
  }
}

.dividend-table__row {
  font-size: 16px;
  line-height: 1.3;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-red);
  margin-bottom: 20px;

  @include responsive.target(md, xl) {
    display: table-row;
    font-size: 18px;
    line-height: 1.55;
  }
}

.dividend-table__column {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    display: table-cell;
    padding-top: 8px;
    padding-bottom: 8px;

    .dividend-table__row:first-of-type > & {
      padding-top: 40px;
    }
  }
}

.dividend-table__label {
  margin-bottom: 4px;
  font-weight: var(--font-weight-bold);

  @include responsive.target(md, xl) {
    display: none;
  }
}

.dividend-table__value {
}

.dividend-table__footnotes {
  margin-top: 40px;

  .text-plugin-content p {
    font-size: 10px;
  }

  @include responsive.target(md, xl) {
    .text-plugin-content p {
      margin-bottom: 0;
    }
  }
}
