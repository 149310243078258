@use '../../libs/styles/helpers';

.emphasis {
  .underlined-headline--red & {
    color: var(--color-black);
  }

  .underlined-headline--black & {
    color: var(--color-red);
  }

  .underlined-headline--heavy & {
    color: var(--color-red);
  }

  cal-teaser & {
    color: var(--cal-teaser-mark-color, currentColor);
  }
}

@include helpers.ckeditor_style {
  .emphasis {
    color: var(--color-red);

    .underlined-headline--red & {
      color: var(--color-black);
    }

    .underlined-headline--black & {
      color: var(--color-red);
    }

    .underlined-headline--heavy & {
      color: var(--color-red);
    }

    .simple-headline--red & {
      color: var(--color-black);
    }
  }
}
