@use '../../libs/styles/responsive';
@use '../../libs/styles/helpers';

.text {
  font-size: 16px;
  line-height: 1.625;
  font-weight: var(--font-weight-regular);
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  cal-button {
    font-size: 16px;
    line-height: 1;
  }

  & + p cal-button,
  & + cal-button
  {
    display: block;
    margin-top: 40px;
  }

  @include responsive.target(lg, xl) {
    font-size: 18px;
    line-height: 1.555;
  }
}

.text--narrow {
  max-width: 656px;
  margin-left: auto;
  margin-right: auto;

  .underlined-headline--heavy + & {
    margin-top: 50px;
  }
}


@include helpers.ckeditor_style {
  p {
    @extend .text;
  }

  p.text--narrow {
    @extend .text--narrow;
  }
}
