@use '../../libs/styles/responsive';
@use '../../libs/styles/helpers';

.lead {
  font-size: 24px;
  color: var(--color-grey);
  line-height: 1.2;
  font-weight: var(--font-weight-regular);
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    font-size: 40px;
    line-height: 1.25;
  }
}

.lead--small {
  font-size: 18px;
  line-height: 1.55;
  color: var(--color-black--80);

  @include responsive.target(md, xl) {
    font-size: 24px;
    line-height: 1.4;
  }
}

@include helpers.ckeditor_style {
  .lead {
    @extend .lead;
  }

  .lead--small {
    @extend .lead--small;
  }
}
