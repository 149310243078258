@use 'libs/styles/responsive';

.cal-news-list {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.cal-news-list__item {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-red);

  &:first-child {
    padding-top: 0;
  }

  @include responsive.target(md, xl) {
    &.cal-news-list__item--narrow {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.cal-basic-news-teaser {
  @include responsive.target(md, xl) {
    display: flex;
  }
}

.cal-basic-news-teaser__date {
  font-weight: var(--font-weight-bold);
  line-height: 1.3;
  margin-bottom: 12px;
  flex-shrink: 0;

  @include responsive.target(md, xl) {
    font-weight: var(--font-weight-regular);
    line-height: 1.625;
    font-size: 18px;
    width: 145px;
    margin-bottom: 0;
  }
}

.cal-basic-news-teaser__main {
  line-height: 1.625;
}

.cal-basic-news-teaser__link {
  color: inherit;
  text-decoration: none;
}

.cal-basic-news-teaser__title {
  @include responsive.target(md, xl) {
    &.cal-basic-news-teaser__title--bold {
      font-weight: var(--font-weight-bold);
    }
    font-size: 18px;
  }
}

.cal-basic-news-teaser__pdf {
  --cal-button-primary-color: var(--color-red);
}
