@use 'libs/styles/responsive';
@use 'libs/styles/fonts';
@use './config';

:root {
  /*********** Colors ***********/
  // CALIDA colors
  --color-black: #000000;
  --color-black--80: #333333;
  --color-white: #ffffff;
  --color-grey--rollover: #555555;
  --color-grey: #707070;
  --color-grey--lighter: #EEEEEE;
  --color-grey--mid: #c5c0b1;
  --color-grey--warm: #999581;
  --color-grey--warm-light: #f4f2ef;
  --color-red: #c00000;

  // LAB360 colors
  --color-blue: #3200ff;
  --color-green: #c7ff00;

  /*************** Fonts ***************/
  --font-gotham-ssm: '#{config.$font-gotham-ssm}';
  --font-icon: '#{config.$font-icon}';
  --font-weight-bold: #{fonts.$fw-bold};
  --font-weight-regular: #{fonts.$fw-regular};

  /*************** Dimensions ***************/
  --cms-toolbar-height: 46px;
  --header-height: 56px;
  --content-max-width: 1440px;
  --content-side-padding-default: 16px;
  --content-side-padding-medium: 16px;
  --content-side-padding-large: 16px;
  --content-vertical-padding: 80px;

  @include responsive.target(sm, xl) {
    --content-side-padding-default: 24px;
    --content-side-padding-medium: 24px;
    --content-side-padding-large: 24px;
  }

  @include responsive.target(md, xl) {
    --content-side-padding-default: 56px;
    --content-side-padding-medium: 56px;
    --content-side-padding-large: 56px;
  }

  @include responsive.target(md_landscape, xl) {
    --header-height: 78px;
    --content-side-padding-medium: 56px;
    --content-side-padding-large: 168px;
  }

  @include responsive.target(lg, xl) {
    --content-vertical-padding: 140px;
    --content-side-padding-default: 56px;
    --content-side-padding-medium: 168px;
    --content-side-padding-large: 280px;
  }

  /*************** Layers ***************/
  --main-z-index: 0;
  --content-block-z-index: 10;
  --footer-z-index: 100;
  --navigation-z-index: 200;
  --header-z-index: 300;
  --overlay-z-index: 400;
}
