@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.graphic-on-background {

}

.graphic-on-background__visual {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 0;

  @include responsive.target(md, xl) {
    display: block;
  }
}

.graphic-on-background__image {
  width: 100%;
  height: 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.graphic-on-background__scroll-container {
  @include helpers.no-scrollbar;
  position: relative;
  z-index: 5;
  padding-top: 94px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-bottom: 44px;

  &::after {
    content: '';
    display: block;
    width: var(--content-side-padding-medium);
  }

  @include responsive.target(md, xl) {
    display: block;
    padding-top: 150px;
    margin-bottom: 80px;
    padding-bottom: 100px;

    &::after {
      content: none;
    }
  }

  @include responsive.target(lg, xl) {
    padding-top: 150px;
    padding-bottom: 370px;
    margin-bottom: 0;
    overflow-x: unset;
    overflow-y: unset;
  }
}

.graphic-on-background__scroll-content {
  padding-left: var(--content-side-padding-medium);
  padding-right: var(--content-side-padding-medium);

  @include responsive.target(md_landscape, xl) {
    max-width: var(--content-max-width);
    margin-left: auto;
    margin-right: auto;
  }
}

.graphic-on-background__graphic {
  min-width: var(--graphic-on-background-graphic-min-width);
  width: 100%;
}

.graphic-on-background__scrollbar {
  position: relative;
  z-index: 5;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 34px;

  @include responsive.target(md, xl) {
    padding-bottom: 60px;
  }

  @include responsive.target(lg, xl) {
    display: none;
  }
}
