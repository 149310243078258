@use 'libs/styles/helpers';

.icon {
  font-family: var(--font-icon);
  font-size: 150px;
  color: var(--color-red);
}

.icon--block {
  display: block;
}

p > .icon--block {
  margin-bottom: -80px;
}

@include helpers.ckeditor_style {
  .icon {
    @extend .icon;
  }

  .icon--block {
    @extend .icon--block;
  }

  p > .icon--block {
    margin-bottom: -80px;
  }
}
