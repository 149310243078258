@use 'libs/styles/responsive';
@use 'libs/styles/helpers';
@use './text';

// Quote
// ---------------------------------------------------
.quote {
  font-size: 24px;
  line-height: 1.55;

  .quote-source + & {
    margin-top: 1em;
  }
}

.brand-highlight .quote {
  font-size: 18px;
  line-height: 1.55;

  @include responsive.target(md, xl) {
    font-size: 24px;
    line-height: 1.5;
  }
}

cal-text-slide .quote {
  color: var(--color-grey);
  font-size: 18px;
  line-height: 1.55;

  @include responsive.target(md, xl) {
    font-size: 30px;
    line-height: 1.25;
  }
}

// Quote Source
// ---------------------------------------------------
.quote-source {
  font-size: 18px;
  line-height: 1.55;
}

.brand-highlight .quote-source {
  font-size: 16px;
  line-height: 1.625;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.55;
  }
}

cal-text-slide .quote-source {
  color: var(--color-grey);
  font-size: 14px;
  line-height: 1.35;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.55;
  }
}

@include helpers.ckeditor_style {
  .quote {
    @extend .quote;
  }

  .quote-source {
    @extend .quote-source;
  }
}
