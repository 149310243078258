@use '../../../../libs/styles/responsive';

.cal-teaser-slide-heading {
  position: relative;
  font-weight: var(--font-weight-regular);
  color: var(--color-red);
  letter-spacing: -0.015em;
  font-size: 45px;
  line-height: 1.111;

  @include responsive.target(lg, xl) {
    font-size: 70px;
    line-height: 1.071;
  }
}

.cal-teaser-slide-heading--underlined {
  padding-bottom: 0.45em;

  &::after {
    content: '';
    display: block;
    position: absolute;
    border-bottom: 1px solid currentColor;
    top: 100%;
    left: 0;
    width: 80px;
  }

  @include responsive.target(lg, xl) {
    padding-bottom: 0.55em;

    &::after {
      border-bottom: 2px solid currentColor;
    }
  }
}
