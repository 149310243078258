@use 'libs/styles/responsive';

.two-teasers {

}

.two-teasers__inner {
  padding: var(--content-side-padding-default);
  margin-left: auto;
  margin-right: auto;
  max-width: var(--content-max-width);

  @include responsive.target(lg, xl) {
    display: flex;
    grid-column-gap: 16px;
    align-items: stretch;
  }
}

.two-teasers__column {
  margin-bottom: var(--content-side-padding-default);

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(xs, sm) {
    cal-teaser {
      --cal-teaser-height: 100%;
      --cal-teaser-aspect-ratio: 1;
      --cal-teaser-link-position: absolute;
    }
  }

  @include responsive.target(md, xl) {
    margin-bottom: 16px;
  }

  @include responsive.target(lg, xl) {
    flex-shrink: 0;
    flex-basis: 0;
    flex-grow: 1;
    margin-bottom: 0;
  }
}

