.cal-iframe-teaser {
  position: relative;
  padding-top: 100%;
  width: 100%;
  background-color: var(--color-grey--warm-light);
  overflow: hidden;
}

.cal-iframe-teaser__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
