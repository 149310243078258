@use 'libs/styles/responsive';

.cal-news-grid {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(md, xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  @include responsive.target(lg, xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include responsive.target(xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.cal-news-grid__item {
  margin-bottom: 60px;

  @include responsive.target(md, xl) {
    margin-bottom: 0;
  }
}

.cal-grid-news-teaser {
  @include responsive.target(md, xl) {
    position: relative;
    padding-top: 100%;
  }
}

.cal-grid-news-teaser__link {
  color: inherit;
  text-decoration: none;
  display: block;

  @include responsive.target(md, xl) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background-color ease-out 0.15s;

    &:hover {
      background-color: var(--color-grey--warm-light);
    }
  }
}

.cal-grid-news-teaser__inner {
  display: block;

  @include responsive.target(md, xl) {
    padding-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 30px;
  }
}

.cal-grid-news-teaser__title {
  position: relative;
  font-weight: var(--font-weight-bold);
  font-size: 22px;
  line-height: 1.22;
  padding-bottom: 30px;
  margin-bottom: 12px;
  display: block;

  &::after {
    content: '';
    display: block;
    border-bottom: 2px solid var(--color-black);
    width: 80px;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.cal-grid-news-teaser__date {
  display: block;
  font-size: 14px;
  line-height: 1.35;
  margin-bottom: 2px;
}

.cal-grid-news-teaser__ad-hoc {
  display: block;
  font-size: 14px;
  line-height: 1.35;
  text-transform: uppercase;
  color: var(--color-red);
  font-weight: var(--font-weight-bold);
}
