@use '../../libs/styles/helpers';

.basic-link {
  color: var(--color-red);
  text-decoration: none;
}

@include helpers.ckeditor_style {
  a {
    @extend .basic-link;
  }
}
