@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.timeline {
  padding-top: 80px;
  padding-bottom: 80px;

  @include responsive.target(lg, xl) {
    padding-top: 156px;
    padding-bottom: 172px;
  }
}

.timeline__inner {
  position: relative;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);

  @include responsive.target(md, xl) {
    padding-left: 168px;
    padding-right: 168px;
  }

  @include responsive.target(lg, xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.timeline__scroll-container {
  position: relative;

  @include responsive.target(lg, xl) {
    @include helpers.no-scrollbar;
    overflow-x: auto;
    display: flex;
    padding-left: calc(50vw - #{var(--content-max-width)} * 0.5 + 118px);
    padding-right: calc(50vw - #{var(--content-max-width)} * 0.5 + 118px);
  }
}

.timeline__content {
  @include responsive.target(lg, xl) {
    display: flex;
  }
}

.timeline__line {
  position: absolute;
  top: 0;
  left: 50%;
  height: auto;
  bottom: 0;
  z-index: 0;
  border-left: 1px solid var(--color-grey--mid);

  @include responsive.target(lg, xl) {
    top: 50%;
    border-left: 0;
    border-top: 1px solid var(--color-grey--mid);
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
  }
}

// Timeline Period
// ----------------------------------------------------
.timeline-period {
  --timeline-period-height: 800px;
  position: relative;
  margin-bottom: 60px;
  z-index: 5;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(lg, xl) {
    display: flex;
    margin-bottom: 0;
    height: var(--timeline-period-height);
    width: 420px;

    &:nth-child(even) {
      align-items: flex-end;
    }
  }
}

.timeline-period__top {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @include responsive.target(lg, xl) {
    position: relative;
    height: 100%;
    z-index: 5;
    align-items: center;
    margin-bottom: 0;
    margin-right: -50px;
  }
}

.timeline-period__year {
  border-radius: 50%;
  background-color: var(--color-grey--warm);
  color: var(--color-white);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: var(--font-weight-bold);

  @include responsive.target(lg, xl) {
    width: 100px;
    height: 100px;
    font-size: 24px;
  }
}

.timeline-period__entries {

  .timeline-period--two-columns & {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 32px;

  }

  @include responsive.target(lg, xl) {
    .timeline-period--two-columns & {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
    }
  }
}

// Timeline Entry
// ----------------------------------------------------
.timeline-entry {
  display: flex;
  align-items: center;

  .timeline-period--two-columns & {
    flex-direction: column;
    width: 100%;
  }

  @include responsive.target(lg, xl) {
    height: calc(#{var(--timeline-period-height)} * 0.5);
    align-items: flex-start;
    border-left: 1px solid var(--color-grey--mid);
    padding-right: 80px;

    .timeline-period:nth-child(even):not(.timeline-period--two-columns) & {
      padding-top: 70px;
    }

    .timeline-period--two-columns & {
      flex-direction: row-reverse;
      padding-left: 20px;
    }

    &:nth-child(even) {
      padding-top: 70px;
    }
  }
}

.timeline-entry--visual-left {
  flex-direction: row;

  @include responsive.target(lg, xl) {
    flex-direction: row-reverse;
  }
}

.timeline-entry--visual-right {
  flex-direction: row-reverse;
}


.timeline-entry__visual {
  width: 50%;
  align-self: flex-start;

  .timeline-entry--visual-left & {
    padding-right: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .timeline-entry--visual-right & {
    padding-left: 30px;
  }

  .timeline-period--two-columns & {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @include responsive.target(lg, xl) {
    .timeline-entry--visual-left &,
    .timeline-entry--visual-right & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.timeline-entry__icon {
  font-size: 150px;
  color: var(--color-red);
}

.timeline-entry__text {
  width: 50%;

  .text-plugin-content {
    p, ul {
      font-size: 14px;
      line-height: 1.35;
      font-weight: var(--font-weight-bold);
      color: var(--color-grey--warm);
    }

    ul {
      padding-left: 0;
      list-style: none;
    }

    p + ul {
      margin-top: -1em;
    }

    li {
      margin-bottom: 0;
      display: flex;

      &::before {
        content: '•';
        display: block;
        margin-right: 0.5ch;
      }
    }

    .emphasis {
      color: var(--color-black);
    }
  }

  .timeline-entry--visual-left & {
    padding-left: 16px;
  }

  .timeline-entry--visual-right & {
    padding-right: 16px;
  }

  .timeline-period--two-columns & {
    width: 100%;
  }

  @include responsive.target(lg, xl) {
    min-width: 220px;
    margin-right: 40px;

    .text-plugin-content {
      p, ul {
        font-size: 18px;
      }
    }

    .timeline-entry--visual-left &,
    .timeline-entry--visual-right & {
      padding-left: 20px;
      padding-right: 0;
    }

    .timeline-period--two-columns & {
      padding-left: 0;
    }
  }
}


// Timeline Scrollbar
// ----------------------------------------------------
.timeline__scrollbar {
  display: none;

  @include responsive.target(lg, xl) {
    display: block;
    margin-top: 106px;
    max-width: var(--content-max-width);
    margin-left: auto;
    margin-right: auto;
    padding-left: 118px;
    padding-right: 118px;
  }
}
