@use 'libs/styles/responsive';

.facts-and-figures {
}
.facts-and-figures--4-columns {
}

.facts-and-figures__inner {
  padding-top: 80px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 66px;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(md, xl) {
    padding-bottom: 100px;
  }

  @include responsive.target(lg, xl) {
    padding-top: 120px;
    padding-left: var(--content-side-padding-large);
    padding-right: var(--content-side-padding-large);
    padding-bottom: 160px;

    .facts-and-figures--4-columns & {
      padding-left: 200px;
      padding-right: 200px;
    }
  }
}

.facts-and-figures__grid {
  display: grid;
  grid-column-gap: 18px;
  grid-row-gap: 60px;
  grid-template-columns: 1fr;

  .facts-and-figures--4-columns & {
    grid-template-columns: repeat(2, 1fr);
  }

  @include responsive.target(md, xl) {
    grid-template-columns: repeat(3, 1fr);

    .facts-and-figures--4-columns & {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include responsive.target(lg, xl) {
    grid-column-gap: 60px;
    grid-row-gap: 80px;

    .facts-and-figures--4-columns & {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.facts-and-figures__item {
}

.fact {
  padding-left: 16px;
  padding-right: 16px;
}

.fact__visual {
  position: relative;
  height: 150px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  @include responsive.target(lg, xl) {
    width: 150px;
    height: 150px;
  }
}

.fact__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 150px;
  transform: translate(-50%, -50%);
  color: var(--color-red);
}

.fact__number {
  position: relative;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  color: var(--color-grey--warm);

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 80px;
    height: 2px;
    transform: translateX(-50%);
    background-color: var(--color-grey--warm);
  }

  @include responsive.target(lg, xl) {
    font-size: 70px;
  }
}

.fact__description {
  font-size: 16px;
  line-height: 1.3125;
  text-align: center;

  @include responsive.target(lg, xl) {
    font-size: 18px;
    line-height: 1.555;
  }
}
