@use 'libs/styles/responsive';
@use 'libs/styles/helpers';
@use './text';

.meta-text {
  @extend .text;
  color: var(--color-grey--warm);
}

@include helpers.ckeditor_style {
  .meta-text {
    @extend .meta-text;
  }
}
