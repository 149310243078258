@use 'libs/styles/responsive';

.person-teaser {
}

.person-teaser__link {
  color: inherit;
  text-decoration: none;
}

.person-teaser__visual {
  position: relative;
  padding-top: 117.5%;
  background-color: var(--color-grey--warm-light);
  margin-bottom: 8px;
  overflow: hidden;
  display: block;
}

.person-teaser__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.person-teaser__body {
  display: block;
  hyphens: auto;
}

.person-teaser__name {
  font-size: 14px;
  line-height: 1.35;
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
  display: block;
}

.person-teaser__first-name,
.person-teaser__last-name {
  display: block;

  @include responsive.target(md, xl) {
    display: inline;
  }
}

.person-teaser__function {
  font-size: 14px;
  line-height: 1.35;
  font-weight: var(--font-weight-bold);
  color: var(--color-grey--warm);
}
