@use 'libs/styles/responsive';

cal-button[appearance="raised"] {
  width: 100%;

  @include responsive.target(md, xl) {
    width: auto;
  }
}

.button--red {
  --cal-button-primary-color: var(--color-red);
  --cal-button-secondary-color: var(--color-white);

  &:hover {
    --cal-button-primary-color: var(--color-grey--mid);
    --cal-button-secondary-color: var(--color-red);
    --cal-button-flat-line-color: var(--color-red);
  }
}

.button--white {
  --cal-button-primary-color: var(--color-white);
  --cal-button-secondary-color: var(--color-red);

  &:hover {
    --cal-button-primary-color: var(--color-grey--mid);
    --cal-button-secondary-color: var(--color-white);
    --cal-button-flat-line-color: var(--color-white);
  }
}

.button--grey {
  --cal-button-primary-color: var(--color-grey--warm);
  --cal-button-secondary-color: var(--color-white);

  &:hover {
    --cal-button-primary-color: var(--color-grey--mid);
    --cal-button-secondary-color: var(--color-white);
    --cal-button-flat-line-color: var(--color-grey--warm);
  }
}
