@use 'libs/styles/responsive';

.dark-mood {
  --dark-mood-content-max-width: 880px;
}

.dark-mood__inner {
  padding-top: 96px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 80px;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(md, xl) {
    padding-top: 140px;
  }

  @include responsive.target(md_landscape) {
    padding-left: var(--content-side-padding-large);
    padding-right: var(--content-side-padding-large);
  }

  @include responsive.target(lg, xl) {
    padding-top: 290px;
  }
}

.dark-mood__intro {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--dark-mood-content-max-width);
  color: var(--color-grey--mid);
  margin-bottom: 100px;

  .underlined-headline, .lead {
    color: inherit;
  }
}

.dark-mood__scroll-container {
  width: 100%;
  overflow-x: scroll;
}

.dark-mood__list {
}

.dark-mood__item {
  margin-bottom: 70px;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 120px;
  }

  @include responsive.target(lg, xl) {
    margin-bottom: 300px;
  }
}

.dark-mood-item {
  position: relative;
  width: 100%;

  @include responsive.target(lg, xl) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.dark-mood-item--image-fullscreen {
  display: block;
}
.dark-mood-item--text-left {
  @include responsive.target(md, xl) {
    flex-direction: row-reverse;
  }
}

.dark-mood-item__visual {
}

.dark-mood-item__image-frame {
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.03);
  margin-bottom: 24px;
  padding-top: 100%;

  @include responsive.target(md, xl) {
    padding-top: 0;
  }
}

.dark-mood-item__image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;

  @include responsive.target(md, xl) {
    object-fit: unset;
    object-position: unset;
    position: relative;
  }
}

.dark-mood-item__logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 178px;

  @include responsive.target(lg, xl) {
    max-width: 250px;
    max-height: 80px;
  }
}

.dark-mood-item__main {
  flex-shrink: 0;

  @include responsive.target(lg, xl) {
    padding: 48px;

    .dark-mood-item--image-fullscreen & {
      padding: 0;
      max-width: var(--dark-mood-content-max-width);
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  }
}

.dark-mood-item__text {
  color: var(--color-grey--mid);

  @include responsive.target(lg, xl) {
    max-width: 240px;
  }

  @include responsive.target(lg, xl) {
    max-width: 320px;
  }
}

.dark-mood-item__button-container {
  position: relative;
  margin-top: 40px;
}
