@use 'libs/styles/responsive';

.contact-columns {
}

.contact-columns__inner {
  padding-top: 56px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 80px;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(md, xl) {
    padding-bottom: 120px;
  }
}

.contact-columns__title {
  margin-bottom: 60px;

  @include responsive.target(md, xl) {
    margin-bottom: 80px;
  }
}

.contact-columns__grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 60px;

  @include responsive.target(md, xl) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 80px;
    grid-gap: 53px;
  }

  @include responsive.target(lg, xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.contact-columns__item {
}
