@use 'libs/styles/responsive';
@use 'libs/styles/helpers';

.underlined-headline {
  position: relative;
  font-size: 32px;
  line-height: 1.156;
  font-weight: var(--font-weight-regular);
  color: var(--color-red);
  letter-spacing: -0.015em;
  padding-bottom: 0.45em;
  margin-bottom: 1em;

  &::after {
    content: '';
    display: block;
    position: absolute;
    border-bottom: 1px solid currentColor;
    top: calc(100% - 1px);
    left: 0;
    width: 80px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p + & {
    margin-top: 2em;
  }

  @include responsive.target(lg, xl) {
    font-size: 40px;
    line-height: 1.25;
  }
}

.underlined-headline--sm {
  font-size: 24px;
  line-height: 1.2;
  padding-bottom: 0.5em;

  @include responsive.target(md, xl) {
    font-size: 30px;
  }
}

.underlined-headline--black {
  color: var(--color-black);
}

.underlined-headline--heavy {
  color: var(--color-black);
  font-weight: var(--font-weight-bold);
  font-size: 22px;
  line-height: 1.22;
  padding-bottom: 1em;
  max-width: 440px;

  &::after {
    border-color: var(--color-red);
    border-width: 2px;
  }

  & + cal-button {
    display: block;
    margin-top: -16px;
  }

  .underlined-headline + & {
    margin-top: 80px;
  }

  .text--narrow + & {
    margin-top: 84px;
  }

  @include responsive.target(md, xl) {
    font-size: 40px;
    line-height: 1.125;

    & + cal-button {
      margin-top: -32px;
    }
  }

  @include responsive.target(lg, xl) {
    .underlined-headline + & {
      margin-top: 120px;
    }

    .text--narrow + & {
      margin-top: 178px;
    }
  }
}

@include helpers.ckeditor_style {
  .underlined-headline {
    @extend .underlined-headline;
  }

  .underlined-headline--sm {
    @extend .underlined-headline--sm;
  }

  .underlined-headline--black {
    @extend .underlined-headline--black;
  }

  .underlined-headline--heavy {
    @extend .underlined-headline--heavy;
  }
}
