@use 'libs/styles/responsive';

.text-wrapper {
  position: relative;
  background-color: var(
    --content-block-override-background-color,
    var(--color-white)
  );
  z-index: var(--content-block-z-index);
}

.text-wrapper .text-plugin-content {
  padding-top: 80px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 80px;
  max-width: 897px;
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(lg, xl) {
    padding-top: 120px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 120px;
  }
}

.timeline-text-wrapper .text-plugin-content {
  padding-top: 30px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 0;
  font-size: 14px;
  @include responsive.target(xs, sm) {
    p {
      font-size: 14px;
    }
  }
  @include responsive.target(lg, xl) {
    padding-top: 40px;
    max-width: var(--content-max-width);
    margin-left: auto;
    margin-right: auto;
    padding-left: 118px;
    padding-right: 118px;
    padding-bottom: 0;
  }
}

.text-wrapper.detail-page__header .text-plugin-content {
  padding-bottom: 0;
}

.text-wrapper + .text-wrapper .text-plugin-content {
  padding-top: 0;
}
