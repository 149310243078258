@use 'libs/styles/responsive';
@use 'libs/styles/helpers';

.separator,
hr {
  display: block;
  width: 80px;
  margin-top: 50px;
  margin-bottom: 30px;
  margin-left: 0;
}

@include helpers.ckeditor_style {
  .separator,
  hr {
    @extend .separator;
  }
}
