@use 'libs/styles/responsive';
@use 'libs/styles/helpers';

.simple-headline {
  font-size: 32px;
  line-height: 1.156;
  font-weight: var(--font-weight-regular);
  color: var(--color-black);
  letter-spacing: -0.015em;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(lg, xl) {
    font-size: 40px;
    line-height: 1.25;
  }
}

.simple-headline--red {
  color: var(--color-red);
}

.simple-headline--large {
  font-size: 45px;
  line-height: 1.11;

  @include responsive.target(lg, xl) {
    font-size: 70px;
    line-height: 1.07;
  }
}

@include helpers.ckeditor_style {
  .simple-headline {
    @extend .simple-headline;
  }

  .simple-headline--red {
    @extend .simple-headline--red;
  }

  .simple-headline--large {
    @extend .simple-headline--large;
  }
}
