@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.sustainability-impact-overview__content {
  display: grid;
  grid-template-columns: auto auto;
  padding: 223px 56px 100px;
  max-width: var(--content-max-width);
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);

  @include responsive.target(xs, md) {
    display: flex;
    flex-direction: column;
    padding: 86px 24px 100px; //86 = 60 + 26
  }
}

.sustainability-impact-item {
  position: relative;
  display: flex;
  gap: 53px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 13px;
  padding-right: 24px;
  border-right: 1px solid var(--color-grey--mid);

  @include responsive.target(xs, md) {
    display: flex;
    flex-direction: column;
    padding-top: 140px;
    padding-bottom: 40px;
    padding-left: 0px;
    padding-right: 0px;
    border-right: none;
  }
}

.sustainability-impact-item:after {
  content: '';
  position: absolute;
  height: 1px;
  width: calc(100% - 16px);
  bottom: 0;
  right: 7px;
  background-color: var(--color-grey--mid);
}

.sustainability-impact-item:nth-of-type(1) {
  padding-top: 0px;
  @include responsive.target(xs, md) {
    padding-top: 137px;
  }
}
.sustainability-impact-item:nth-of-type(2) {
  padding-top: 0px;
  @include responsive.target(xs, md) {
    padding-top: 137px;
  }
}

.sustainability-impact-item:nth-of-type(2n) {
  padding-left: 59px;
  padding-right: 13px;
  border-right: none;
  @include responsive.target(xs, md) {
    padding-top: 137px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.sustainability-impact-item:nth-last-of-type(1):after {
  display: none;
}
.sustainability-impact-item:nth-of-type(odd):nth-last-of-type(2):after {
  display: none;
  @include responsive.target(xs, md) {
    display: block;
  }
}

.sustainability-impact-item__icon {
  font-size: 150px;
  min-width: 150px;
  color: var(--color-red);

  @include responsive.target(xs, md) {
    position: absolute;
    top: 60px;
    right: 36px; //60 - 24
    font-size: 90px;
    min-width: unset;
  }
}

.sustainability-impact-item__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sustainability-impact-item__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.36px;
  color: var(--color-red);
}

.sustainability-impact-item__text {
  line-height: 1.3;
}

#sustainability-direct-impacts {
  top: 60px;
}
#sustainability-indirect-impacts {
  top: 60px;
}
