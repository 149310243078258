@use 'libs/styles/responsive';

.teaser-carpet {
}

.teaser-carpet--large-left {
}

.teaser-carpet--large-center {
}

.teaser-carpet--large-right {
}

.teaser-carpet--all-small {
}

.teaser-carpet--with-stats {
  @include responsive.target(xs, sm) {
    .cal-iframe-teaser {
      padding-top: 0;
      margin-left: calc(#{var(--content-side-padding-default)} * -1);
      width: calc(100% + #{var(--content-side-padding-default)} * 2);
      height: 600px;
    }
  }
}

.teaser-carpet__inner {
  //position: relative;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--content-side-padding-default);
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: var(--content-side-padding-default);

  @include responsive.target(md_landscape) {
    padding-left: var(--content-side-padding-large);
    padding-right: var(--content-side-padding-large);
  }
}

.teaser-carpet__grid {
  @include responsive.target(xs, sm) {
    .teaser-carpet--with-stats & {
      display: flex;
      flex-direction: column;
    }
  }

  @include responsive.target(md, xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;

    grid-template-areas:
      'large large'
      'large large'
      'small-1 small-2'
      'small-3 small-4';

    .teaser-carpet--with-stats & {
      grid-template-areas:
      'large large'
      'large large'
      'stat  stat'
      'small-1 small-2';
    }
  }

  @include responsive.target(lg, xl) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'large large small-1 small-2'
      'large large small-3 small-4';

    .teaser-carpet--large-left & {
      grid-template-areas:
        'large large small-1 small-2'
        'large large small-3 small-4';
    }

    .teaser-carpet--large-center & {
      grid-template-areas:
        'small-1 large large small-3'
        'small-2 large large small-4';
    }

    .teaser-carpet--large-right & {
      grid-template-areas:
        'small-1 small-3 large large'
        'small-2 small-4 large large';
    }

    .teaser-carpet--all-small & {
      grid-template-areas:
        'small-1 small-2 small-3 small-4'
        'small-5 small-6 small-7 small-8';
    }

    .teaser-carpet--with-stats & {
      grid-template-areas:
        'small-1 small-2 large large'
        'stat    stat    large large';
    }
  }
}

.teaser-carpet__item {
  opacity: 0;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .teaser-carpet--with-stats & {
    &.teaser-carpet__item--large {
      order: -1;
    }

    &.teaser-carpet__item--stats {
      order: -1;
      margin-bottom: 20px;
    }
  }

  @include responsive.target(md, xl) {
    margin-bottom: 0;

    &.teaser-carpet__item--large {
      grid-area: large;
    }

    &.teaser-carpet__item--stats {
      grid-area: stat;
    }
  }

  @include responsive.target(lg, xl) {
    .teaser-carpet--all-small &.teaser-carpet__item--large {
      grid-area: unset;
    }
  }
}

.teaser-carpet__stats {
  --cal-stat-primary-color: var(--color-grey--warm);

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 28px;
  grid-row-gap: 56px;
  padding-top: 36px;
  padding-bottom: 36px;

  @include responsive.target(md, xl) {
    padding-top: 0;
    padding-bottom: 0;
    grid-row-gap: 40px;
  }

  @include responsive.target(lg, xl) {
    --cal-stat-value-font-size: 70px;
    display: flex;
    grid-column-gap: 60px;
  }
}

.teaser-carpet__stats cal-stat:nth-child(4) {
  @include responsive.target(md, xl) {
    display: none;
  }
}

.teaser-carpet__visual {
  position: absolute;
  top: 100px;
  left: 100%;
  transform: translateX(-184px);
  width: 184px;
  height: 409px;
  pointer-events: none;

  @include responsive.target(md, xl) {
    top: 0;
    height: 762px;
  }

  @include responsive.target(lg, xl) {
    top: auto;
    left: 0;
    height: calc(100% + 120px);
    bottom: 0;
    width: 100%;
    transform: none;
  }
}

.teaser-carpet__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  --cal-parallax-image-background-size: auto 100%;

  @include responsive.target(md, xl) {
    left: -162px;
    right: 0;
    width: auto;
  }

  @include responsive.target(lg, xl) {
    left: calc(50% + #{var(--content-max-width)} * 0.5 - 474px);
    right: 0;
    width: auto;
  }
}
