@use 'libs/styles/responsive';

.publications-list {
}

.publications-list__inner {
  position: relative;
  padding-top: 74px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 99px;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(md, xl) {
    padding-top: 128px;
    padding-bottom: 168px;
  }

  @include responsive.target(lg, xl) {
    padding-top: 168px;
    padding-bottom: 208px;

    .publications-list--has-image & {
      min-height: 100vh;
    }
  }
}

.publications-list__main {
}

.publications-list__news-with-filters {
  --cal-news-select-arrow-color: var(--color-white);
  --cal-news-select-option-color: var(--color-white);
}

.publications-list__list {
  margin-top: 40px;

  @include responsive.target(lg, xl) {
    .cal-basic-news-teaser__main {
      max-width: 400px;
    }
  }
}

.publications-list__visual {
  display: none;
  height: calc(100% + 84px);
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  aspect-ratio: 2 / 3;
  max-height: calc(100vh + 84px);

  --cal-parallax-image-focus-x: left;
  --cal-parallax-image-focus-y: top;

  @include responsive.target(lg, xl) {
    display: block;
  }
}
