@use 'libs/styles/responsive';

.small-parallax-teaser {
  position: relative;
  background-color: var(--color-grey--warm-light);
}

.small-parallax-teaser__inner {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  padding-left: var(--content-side-padding-large);
  padding-right: var(--content-side-padding-large);
  padding-bottom: 104px;

  @include responsive.target(md, xl) {
    padding-top: 56px;
    padding-bottom: 133px;
  }
}

.small-parallax-teaser__visual {
  --visual-y-overlap: 60px;
  position: absolute;
  z-index: 0;
  top: calc(-1 * var(--visual-y-overlap));
  right: 0;
  height: calc(100% + #{var(--visual-y-overlap)});
  width: 60%;

  @include responsive.target(md, xl) {
    --visual-y-overlap: 160px;
    width: 50%;
  }

  @include responsive.target(lg, xl) {
    width: calc(50% - 32px);
  }
}

.small-parallax-teaser__image {
  width: 100%;
  height: 100%;
  transform: translateY(10%);
  --cal-parallax-image-background-size: auto 100%;
  --cal-parallax-image-focus-x: left;
  --cal-parallax-image-focus-y: bottom;
}

.small-parallax-teaser__content {
  position: relative;
  z-index: 5;
  max-width: 220px;

  @include responsive.target(md, xl) {
    max-width: 50%;
  }

  @include responsive.target(lg, xl) {
    max-width: 544px;
  }
}
