@use '../../libs/styles/helpers';
@use 'text';

.list-item {
  margin-bottom: 0.5rem;
}

.list {
  @extend .text;
  padding-left: 2rem;
}
ul.list {
  list-style: disc;
}
ol.list {
  list-style: decimal;
}

@include helpers.ckeditor_style {
  ul {
    @extend .list;
  }

  ol {
    @extend .list;
  }

  li {
    @extend .list-item;
  }
}
