@use 'libs/styles/responsive';

.detail-page {
}

.detail-page__inner {
  padding-top: 96px;
  padding-bottom: 60px;
}

.detail-page__back {
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  max-width: 897px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -40px;
  position: relative;
  z-index: calc(#{var(--content-block-z-index)} + 1);

  @include responsive.target(lg, xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.detail-page__back-button {
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.detail-page__arrow {
  stroke: var(--color-red);
}

.detail-page__main {
  .meta-text {
    display: none;
  }

  img {
    margin-bottom: 50px;
  }

  img + hr {
    margin-top: 0;
  }

  @include responsive.target(md, xl) {
    .meta-text {
      display: block;
      margin-top: 70px;
      margin-bottom: 50px;
    }

    img {
      margin-bottom: 70px;
    }
  }
}

.detail-page__header {
  margin-bottom: 40px;

  @include responsive.target(md, xl) {
    margin-bottom: 0;
  }
}

.media-share {
  .detail-page__header + & {
    margin-top: 40px;
  }
}

.media-share__inner {
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  max-width: 897px;
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(md_landscape, xl) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include responsive.target(lg, xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.media-share__downloads {
  margin-bottom: 60px;

  @include responsive.target(md, xl) {
    display: flex;
    grid-gap: 32px;
  }

  @include responsive.target(md_landscape, xl) {
    grid-gap: 24px;
    margin-bottom: 0;
  }
}

.media-share__download-button {
  width: 100%;
  margin-bottom: 24px;
  display: block;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 0;
  }

  @include responsive.target(lg, xl) {
    flex-shrink: 0;
    width: auto;
  }
}

.media-share__share {
  display: flex;
  justify-content: center;
  grid-gap: 32px;
}

.media-share__icon-button {
  position: relative;
  display: block;
  font-size: 60px;
  text-decoration: none;
  color: var(--color-grey);
  width: 24px;
  height: 24px;
}

.media-share__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
