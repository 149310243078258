@use '../../libs/styles/helpers';

.superscript,
sup
{
  vertical-align: super;
  font-size: 0.75em;
}

@include helpers.ckeditor_style {
  sup {
    @extend .superscript;
  }
}


