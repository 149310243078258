@use 'libs/styles/responsive';

.news-overview {
}

.news-overview__inner {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
  padding-bottom: 80px;

  @include responsive.target(md, xl) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.news-overview__news-with-filters {
}

.news-overview__outlet {
}
