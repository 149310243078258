@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.kpis-overview {
  position: relative;
}

.kpis-overview__inner {
  padding-top: 94px;
  padding-bottom: 34px;
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;

  @include responsive.target(lg, xl) {
    padding-top: 155px;
    padding-bottom: 346px;
  }

  @include responsive.target(xl) {
    padding-left: var(--content-side-padding-medium);
    padding-right: var(--content-side-padding-medium);
  }
}

.kpis-overview__title {
  margin-bottom: 40px;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);

  @include responsive.target(xs, sm) {
    .underlined-headline {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  @include responsive.target(lg, xl) {
    margin-bottom: 76px;
  }

  @include responsive.target(xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.kpis-overview__scroll-container {
  @include helpers.no-scrollbar;
  overflow-x: auto;
}

.kpis-overview__scroll-content {
  // this is important because of the scroll-container.
  // without inline-block, the padding right isn't respected.
  display: inline-block;

  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);

  @include responsive.target(xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.kpis-overview__steps {
  display: grid;
  grid-template-columns: repeat(5, 208px);
  grid-column-gap: 16px;
  counter-reset: step;

  @include responsive.target(lg, xl) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.kpis-overview__step {
}

.kpis-card {
  background-color: var(--color-white);
  height: 100%;
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;

  @include responsive.target(lg, xl) {
    padding-bottom: 0;
  }
}

.kpis-card__cta {
  display: block;
  margin-top: 24px;
  line-height: normal;
  @include responsive.target(md, xl) {
    margin-top: 36px;
  }
}

.kpis-card__icon {
  font-size: 150px;
  color: var(--color-red);
  text-align: center;
}

.kpis-card__text {
  font-size: 18px;
  line-height: 1.38;
  font-weight: var(--font-weight-bold);
  color: var(--color-grey--warm);
  flex-grow: 1;
  margin-bottom: 24px;

  .emphasis {
    color: rgba(0, 0, 0, 0.8);
  }
}

.kpis-card__number {
  height: 160px;
  color: var(--color-grey--warm-light);
  font-weight: var(--font-weight-bold);
  font-size: 96px;

  &::before {
    counter-increment: step;
    content: counter(step);
  }

  @include responsive.target(lg, xl) {
    height: 188px;
  }
}

.kpis-overview__visual {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 430px;
  background-size: cover;
  pointer-events: none;

  @include responsive.target(lg, xl) {
    height: 950px;
    background-position: center;
  }
}

.kpis-overview__image {
}

.kpis-overview__image--mobile {
  @include responsive.target(md, xl) {
    display: none;
  }
}

.kpis-overview__image--desktop {
  display: none;

  @include responsive.target(md, xl) {
    display: block;
  }
}

.kpis-overview__scrollbar {
  position: relative;
  z-index: 5;
  padding-left: var(--content-side-padding-default);
  padding-right: var(--content-side-padding-default);
}
