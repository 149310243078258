@use 'libs/styles/responsive';

.parallax-teaser {
  position: relative;
}
.parallax-teaser--visual-on-top {
}
.parallax-teaser--right {
}
.parallax-teaser--middle {
}

.parallax-teaser .teaser {
  --cal-teaser-text-size: 22px;

  @include responsive.target(lg, xl) {
    --cal-teaser-text-size: 40px;
  }
}

.parallax-teaser__inner {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: 348px;
  padding-left: var(--content-side-padding-default);
  padding-bottom: var(--content-side-padding-default);

  @include responsive.target(lg, xl) {
    height: 800px;
    padding-top: 0;
    padding-right: var(--content-side-padding-default);
    padding-bottom: 100px;
    display: flex;
    align-items: flex-end;

    .parallax-teaser--right & {
      justify-content: flex-end;
    }

    .parallax-teaser--middle & {
      padding-bottom: 0;
      align-items: center;
    }

    .parallax-teaser--top & {
      align-items: flex-start;
      padding-top: 100px;
      padding-bottom: 0;
    }
  }
}

.parallax-teaser__visual {
  position: absolute;
  top: -24px;
  left: 0;
  width: 100%;
  height: calc(100% + 24px);
  pointer-events: none;

  @include responsive.target(md, xl) {
    top: -100px;
    height: calc(100% + 100px);
    width: 100%;
  }

  @include responsive.target(lg, xl) {
    transform: translateY(var(--parallax-teaser-image-y-offset));

    .parallax-teaser--visual-on-top & {
      z-index: 10;
    }
  }
}

.parallax-teaser__image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
}

.parallax-teaser__image--mobile {
  @include responsive.target(lg, xl) {
    display: none;
  }
}

.parallax-teaser__image--desktop {
  display: none;

  @include responsive.target(lg, xl) {
    display: block;
    --cal-parallax-image-background-size: auto 100%;
  }

  // when entire Image visible -> force alignment
  @media screen and (min-width: 2310px) {
    .parallax-teaser--image-align-right & {
      left: auto;
      transform: none;
      right: 0;
      --cal-parallax-image-focus-x: 100% !important;
    }

    .parallax-teaser--image-align-left & {
      right: auto;
      left: 0;
      transform: none;
      --cal-parallax-image-focus-x: 0% !important;
    }
  }
}

.parallax-teaser__content {
  position: relative;
  z-index: 1;
  max-width: 624px;
}
