@use 'libs/styles/responsive';

.anchor-title {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  display: block;
  font-weight: var(--font-weight-bold);
  color: var(--color-red);
  text-decoration: none;

  .anchor-container:not(.anchor-container--no-children) & {
    margin: 40px;

    @include responsive.target(xs, md) {
      margin: 24px;
    }
  }

  .content-block & {
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    width: auto;
    padding-left: var(--content-side-padding-default);
    padding-right: var(--content-side-padding-default);
    z-index: 10;
    margin: 0 !important;
    text-align: center;
  }

  .content-block--black & {
    color: var(--color-grey--warm);
  }

  .content-block--red & {
    color: var(--color-white);
  }

  @include responsive.target(md, xl) {
    font-size: 18px;

    .content-block & {
      position: absolute;
      top: 40px;
    }
  }
}
