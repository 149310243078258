@use 'libs/styles/responsive';

cal-text-slide {
}

cal-text-slide .icon {
  @include responsive.target(xs, md_landscape) {
    position: absolute;
    top: 0;
    line-height: 1;
  }
}

cal-text-slide .text-plugin-content p + .underlined-headline {
  @include responsive.target(xs, md_landscape) {
    margin-top: -18px;
  }
}

.text-slide--top {
  --cal-text-slide-align: flex-start;
  --cal-text-slide-padding-top: 150px;
  --cal-text-slide-padding-bottom: 0;
}

.text-slide--middle {
  --cal-text-slide-align: center;
  --cal-text-slide-padding-bottom: 0;
}

.text-slide--bottom {
  --cal-text-slide-align: flex-end;
  --cal-text-slide-padding-top: 0px;
  --cal-text-slide-padding-bottom: 150px;
}

.text-slide--left {
  --cal-text-slide-justify: flex-start;
}

.text-slide--right {
  --cal-text-slide-justify: flex-end;
}
