.base {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}
.base--no-header-overlap {
}

.base__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--header-z-index);
  transition: transform ease-out 0.2s, background-color ease-out 0.2s;
  background-color: transparent;

  .cms-toolbar-expanded & {
    top: var(--cms-toolbar-height);
    background-color: var(--color-white);
  }

  &.base__header--scrolled {
    background-color: var(--color-white);
  }

  .base--navigation-open & {
    background-color: var(--color-black);
  }

  &.base__header--hide {
    transform: translateY(-100%);
  }
}

.base__navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  z-index: var(--navigation-z-index);
  transition: opacity ease-out 0.2s;

  .base--navigation-open & {
    opacity: 1;
    pointer-events: all;
  }
}

.base__main {
  position: relative;
  flex-grow: 1;
  z-index: var(--main-z-index);

  .cms-toolbar-expanded & {
    padding-top: var(--cms-toolbar-height);
  }

  .base--no-header-overlap & {
    padding-top: var(--header-height);
  }
}

.base__footer {
  position: relative;
  z-index: var(--footer-z-index);
}

.base__overlay {
}
