@use 'libs/styles/responsive';

.people-grid {
  --people-grid-item-offset: 188px;
  --people-grid-row-gap: 40px;
  --people-grid-vertical-padding: 40px;

  @include responsive.target(md, xl) {
    --people-grid-row-gap: 32px;
  }

  @include responsive.target(lg, xl) {
    --people-grid-vertical-padding: 80px;
  }
}

.people-grid__inner {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--people-grid-vertical-padding);
  padding-bottom: var(--people-grid-vertical-padding);
  padding-left: var(--content-side-padding-medium);
  padding-right: var(--content-side-padding-medium);

  @include responsive.target(md, xl) {
    .people-grid--compensate-offset & {
      padding-bottom: calc(
        var(--people-grid-vertical-padding) + var(--people-grid-item-offset)
      );
    }
  }
}

.people-grid__title {
  margin-bottom: 40px;
  opacity: 0;

  @include responsive.target(lg, xl) {
    margin-bottom: 80px;
  }
}

.people-grid__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: var(--people-grid-row-gap);
  grid-column-gap: 16px;

  @include responsive.target(md, xl) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;
    grid-column-gap: 56px;
  }

  @include responsive.target(lg, xl) {
    grid-column-gap: 72px;
  }
}

.people-grid__item {
  opacity: 0;

  @include responsive.target(md, xl) {
    .people-grid--offset & {
      &:nth-child(3n + 2) {
        grid-column: 1;
        transform: translateY(var(--people-grid-item-offset));
      }

      &:nth-child(3n + 1) {
        grid-column: 2;
      }

      &:nth-child(3n + 3) {
        grid-column: 3;
        transform: translateY(var(--people-grid-item-offset));
      }
    }
  }
}
