@use 'libs/styles/responsive';

.teaser {
  --cal-teaser-text-size: 22px;
  --cal-teaser-text-line-height: 1.227;
  --cal-teaser-text-weight: var(--font-weight-bold);
}

// Shapes
// ---------------------------------------------------------
.teaser--square {
  --cal-teaser-padding-top: 24px;
  --cal-teaser-padding-left: 24px;
  --cal-teaser-padding-right: 24px;
  --cal-teaser-padding-bottom: 24px;
  --cal-teaser-aspect-ratio: 1;
  --cal-teaser-link-position: absolute;
}

.teaser--rectangle {
  --cal-teaser-button-margin-top: 24px;
  --cal-teaser-aspect-ratio: 0;
  --cal-teaser-link-position: relative;

  @include responsive.target(md, xl) {
    --cal-teaser-button-margin-top: 36px;
    --cal-teaser-padding-top: 30px;
    --cal-teaser-padding-left: 40px;
    --cal-teaser-padding-right: 40px;
    --cal-teaser-padding-bottom: 30px;
  }
}

// Text styles
// ---------------------------------------------------------
.teaser--fw-regular {
  --cal-teaser-text-weight: var(--font-weight-regular);
}

.teaser--fw-bold {
  --cal-teaser-text-weight: var(--font-weight-bold);
}

.teaser--22 {
  --cal-teaser-text-size: 22px;
  --cal-teaser-text-line-height: 1.227;
}

.teaser--30 {
  --cal-teaser-text-size: 30px;
  --cal-teaser-text-line-height: 1.167;

  @include responsive.target(xs, sm) {
    .teaser-carpet &,
    cal-teaser-slide & {
      --cal-teaser-text-size: 22px;
      --cal-teaser-text-line-height: 1.227;
    }
  }
}

.teaser--40 {
  --cal-teaser-text-size: 30px;
  --cal-teaser-text-line-height: 1.167;

  @include responsive.target(xs, sm) {
    .teaser-carpet &,
    cal-teaser-slide & {
      --cal-teaser-text-size: 22px;
      --cal-teaser-text-line-height: 1.227;
    }
  }

  @include responsive.target(md, xl) {
    --cal-teaser-text-size: 40px;
    --cal-teaser-text-line-height: 1.125;
  }
}

// Button color on hover
// ---------------------------------------------------------
.teaser:hover {
  --cal-teaser-button-foreground: var(--color-grey--mid);
}

// White
// ---------------------------------------------------------
.teaser--white {
  --cal-teaser-background: var(--color-white);
  --cal-teaser-rollover-background: var(--color-grey--warm-light);
  --cal-teaser-rollover-opacity: 1;
  --cal-teaser-button-foreground: var(--color-red);
  --cal-teaser-text-color: var(--color-red);
  --cal-teaser-mark-color: var(--color-black);
  --cal-icon-color: var(--color-red);
}
.teaser--white:hover {
  --cal-button-flat-line-color: var(--color-red);
}

// Cream
// ---------------------------------------------------------
.teaser--cream {
  --cal-teaser-background: var(--color-grey--warm-light);
  --cal-teaser-rollover-background: var(--color-white);
  --cal-teaser-rollover-opacity: 1;
  --cal-teaser-button-foreground: var(--color-red);
  --cal-teaser-text-color: var(--color-black);
  --cal-teaser-mark-color: var(--color-red);
  --cal-icon-color: var(--color-red);
}
.teaser--cream:hover {
  --cal-button-flat-line-color: var(--color-red);
}

// Red
// ---------------------------------------------------------
.teaser--red {
  --cal-teaser-background: var(--color-red);
  --cal-teaser-rollover-background: var(--color-black);
  --cal-teaser-rollover-opacity: 0.15;
  --cal-teaser-button-foreground: var(--color-white);
  --cal-teaser-text-color: var(--color-white);
  --cal-teaser-mark-color: var(--color-grey--mid);
  --cal-icon-color: var(--color-white);
}
.teaser--red:hover {
  --cal-button-flat-line-color: var(--color-white);
}

// Black
// ---------------------------------------------------------
.teaser--black {
  --cal-teaser-background: var(--color-black);
  --cal-teaser-rollover-background: var(--color-grey--rollover);
  --cal-teaser-rollover-opacity: 1;
  --cal-teaser-button-foreground: var(--color-white);
  --cal-teaser-text-color: var(--color-white);
  --cal-teaser-mark-color: var(--color-grey--warm);
  --cal-icon-color: var(--color-white);
}
.teaser--black:hover {
  --cal-button-flat-line-color: var(--color-white);
}

// Grey
// ---------------------------------------------------------
.teaser--grey {
  --cal-teaser-background: var(--color-grey--warm);
  --cal-teaser-rollover-background: var(--color-black);
  --cal-teaser-rollover-opacity: 0.15;
  --cal-teaser-button-foreground: var(--color-white);
  --cal-teaser-text-color: var(--color-white);
  --cal-teaser-mark-color: var(--color-black);
  --cal-icon-color: var(--color-white);
}
.teaser--grey:hover {
  --cal-button-flat-line-color: var(--color-white);
}

// Image
// ---------------------------------------------------------
.teaser--image {
  --cal-teaser-background: var(--color-white);
  --cal-teaser-rollover-background: var(--color-white);
  --cal-teaser-rollover-opacity: 0.4;
  --cal-teaser-button-foreground: var(--color-red);
  --cal-teaser-text-grow: 0;
  --cal-teaser-text-color: var(--color-red);
  --cal-teaser-mark-color: var(--color-black);
  --cal-teaser-button-margin-top: 32px;
  --cal-icon-color: var(--color-red);

  @include responsive.target(md, xl) {
    --cal-teaser-padding-top: 120px;
    --cal-teaser-padding-left: 50px;
    --cal-teaser-padding-right: 50px;
  }
}
.teaser--image:hover {
  --cal-button-flat-line-color: var(--color-red);
}
