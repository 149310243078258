@use 'libs/styles/responsive';

.our-benefits {
  color: var(--color-white);
}

.our-benefits__inner {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
  padding-left: var(--content-side-padding-large);
  padding-right: var(--content-side-padding-large);
  padding-bottom: 90px;

  @include responsive.target(md, xl) {
    padding-top: 120px;
  }

  @include responsive.target(lg, xl) {
    padding-top: 218px;
    padding-bottom: 144px;
  }
}

.our-benefits__text {
  font-size: 18px;
  line-height: 1.55;

  @include responsive.target(lg, xl) {
    font-size: 24px;
    line-height: 1.41;
  }
}

.our-benefits__grid {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 60px;

  @include responsive.target(lg, xl) {
    grid-column-gap: 128px;
    grid-row-gap: 96px;

    .our-benefits--items-4 & {
      padding-left: 128px;
      padding-right: 128px;
      grid-template-columns: repeat(2, 1fr);
    }

    .our-benefits--items-6 & {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.our-benefits__item {
}

.benefit {
}

.benefit__visual {
  position: relative;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @include responsive.target(lg, xl) {
    width: 130px;
    height: 130px;
    margin-bottom: 50px;
  }
}

.benefit__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 120px;
  transform: translate(-50%, -50%);

  @include responsive.target(lg, xl) {
    font-size: 150px;
  }
}

.benefit__text {
  text-align: center;
  font-size: 14px;
  line-height: 1.36;
  font-weight: var(--font-weight-bold);
  color: var(--color-grey--mid);

  @include responsive.target(md, xl) {
    font-size: 18px;
  }
}
