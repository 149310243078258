.content-block {
  position: relative;
  display: block;
  width: 100%;
  z-index: var(--content-block-z-index);
}

// Color variations
// --------------------
.content-block--white {
  background-color: var(
    --content-block-override-background-color,
    var(--color-white)
  );
}

.content-block--grey-lighter {
  background-color: var(
    --content-block-override-background-color,
    var(--color-grey--lighter)
  );
}

.content-block--grey-warm-light {
  background-color: var(
    --content-block-override-background-color,
    var(--color-grey--warm-light)
  );
}

.content-block--grey-mid {
  background-color: var(
    --content-block-override-background-color,
    var(--color-grey--mid)
  );
}

.content-block--black {
  background-color: var(
    --content-block-override-background-color,
    var(--color-black)
  );
}

.content-block--red {
  background-color: var(
    --content-block-override-background-color,
    var(--color-red)
  );
}

// Color variations
// --------------------
.content-block-wrapper {
  background-color: var(--content-block-override-background-color);
  display: flex;
  flex-direction: column;
}
.content-block-wrapper--white {
  --content-block-override-background-color: var(--color-white);
}

.content-block-wrapper--grey-lighter {
  --content-block-override-background-color: var(--color-grey--lighter);
}

.content-block-wrapper--grey-warm-light {
  --content-block-override-background-color: var(--color-grey--warm-light);
}

.content-block-wrapper--grey-mid {
  --content-block-override-background-color: var(--color-grey--mid);
}

.content-block-wrapper--red {
  --content-block-override-background-color: var(--color-red);
}
