@use 'libs/styles/helpers';

.warm-grey {
  color: var(--color-grey--warm);
}

@include helpers.ckeditor_style {
  .warm-grey {
    @extend .warm-grey;
  }
}
